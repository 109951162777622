import React from 'react'
import { Layout } from 'antd'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { RSVP, Unsubscribe } from '~pages'

import 'antd/dist/antd.css'
import './App.css'

const App = () => {
  return (
    <Layout className='main-container'>
      <BrowserRouter>
        <Switch>
          <Route component={RSVP} exact path='/rsvp' />
          <Route component={Unsubscribe} exact path='/unsubscribe' />
        </Switch>
      </BrowserRouter>
    </Layout>
  )
}

export default App
