import React from 'react'
import 'babel-polyfill'

import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import App from './App'

import * as serviceWorker from './serviceWorker'

const target = document.querySelector('#root')

if (target) {
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1]

  if (path) {
    history.replace(path)
  }

  render(
    <AppContainer>
      <App />
    </AppContainer>,
    target
  )
}

serviceWorker.unregister()

if (module.hot) {
  module.hot.accept(App, () => {
    render()
  })
}
