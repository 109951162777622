import React, { useEffect, useState } from 'react'
import { Button, Card, Image, Space, Row, Typography, Radio, Col, Input } from 'antd'
import { useLocation } from 'react-router-dom'
import { Header, Footer } from '../../components'

import './RSVP.css'
import { submitRSVPRequest } from '../../services/Api'
const { Meta } = Card
const { Text } = Typography
const RSVP = () => {
  const [message, setMessage] = useState(null)
  const [selectedSize, setSelectedSize] = useState('na')
  const [selectedOption] = useState('accept')
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [emailAddress, setEmailAddress] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const url = new URL(window.location.href)
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(url.search)
      if (searchParams.get('email')) {
        setEmail(searchParams.get('email'))
        setEmailAddress(searchParams.get('email'))
      }
    }
  }, [search, url.search])

  const handleSubmit = async () => {
    setIsDataLoaded(true)
    const updatedResponse = await submitRSVPRequest(firstName, lastName, emailAddress, email, selectedSize)
    setMessage(updatedResponse)
    setIsDataLoaded(false)
    setEmailAddress(null)
    setFirstName('')
    setLastName('')
  }

  const onSelectedSizeChange = (e) => {
    setSelectedSize(e.target.value)
  }

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='home unsubscribe'>
          <Image src='/new-logo.jpeg' width={200} />
          <Card bordered={false} className='input-card' title='You`re Invited!'>
            <Row className='upload-per-colleague'>
              {!isDataLoaded && !message && (
                <div className="product-modal">
                  <div className="product-modal-details">
                    {selectedOption === 'accept' && (
                      <Card
                        bordered={false}
                        cover={
                          <img
                            alt="example"
                            height={240}
                            src="/tshirt.jpg"
                            width={240}
                          />
                        }
                        style={{
                          width: '100%'
                        }}
                      ><Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                          <Row gutter={6}>
                            <Col span={12}><Input onChange={(event) => setFirstName(event.target.value)} placeholder="First Name" value={firstName} /></Col>
                            <Col span={12}><Input onChange={(event) => setLastName(event.target.value)} placeholder="Last Name" value={lastName} /></Col>
                          </Row>
                          <Row>
                            <Col span={24}><Input onChange={(event) => setEmailAddress(event.target.value)} placeholder="Email" value={emailAddress} /></Col>
                          </Row>
                          <Space style={{
                            justifyContent: 'left'
                          }} wrap>
                            <Meta
                              title="T-Shirt Size:"
                            />
                            <Radio.Group defaultValue='na' onChange={onSelectedSizeChange} style={{ lineHeight: '20px' }} value={selectedSize}>
                              <Radio value='S'>Small</Radio>
                              <Radio value='M'>Medium</Radio>
                              <Radio value='L'>Large</Radio>
                              <Radio value='XL'>X-Large</Radio>
                              <Radio value='XXL'>XX-Large</Radio>
                              <Radio value='na'>Do not need</Radio>
                            </Radio.Group>
                          </Space>
                        </Space>
                      </Card>
                    )}<Button
                      disabled={message}
                      fullWidth
                      onClick={() => handleSubmit()}
                      size='large'
                      style={{
                        margin: '20px auto',
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        width: 200
                      }}
                      type="primary"
                    >
                  SAVE MY SEAT!
                    </Button>
                    <br />
                  </div>
                </div>
              )}
              {/* {message && !isDataLoaded && (
                <Text style={{
                  textAlign: 'center',
                  margin: 'auto'
                }} type="success">{message}</Text>
              )} */}
              {message && !isDataLoaded && <Row className='upload-per-colleague'>
                <Text strong style={{ textTransform: 'initial' }}>
                  Thank you for accepting our invitation! Due to the overwhelming response, we will confirm your spot shortly. We`re excited to have you join us and look forward to engaging and valuable sessions together.
                </Text>
              </Row>}
            </Row>
          </Card>
        </Row>
      </div>
      <Footer />
    </>
  )
}

RSVP.propTypes = {}

export default RSVP
