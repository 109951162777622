export const campaignEndpoints = {
  unsubscribe: {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/golf/unsubscribe`
  },
  rvsp: {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/golf/rsvp`
  }
}
