import React, { useEffect, useState } from 'react'
import { Button, Card, Image, Input, Row, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import { Header, Footer } from '../../components'

import './Unsubscribe.css'
import { submitUnsubcribeRequest } from '../../services/Api'

const { Text } = Typography
const Unsubscribe = () => {
  const [message, setMessage] = useState(null)
  const [emailAddress, setEmailAddress] = useState(null)
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const url = new URL(window.location.href)
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(url.search)
      if (searchParams.get('email')) {
        setEmailAddress(searchParams.get('email'))
        handleSubmit(searchParams.get('email'))
      }
    }
  }, [search, url.search])

  const handleSubmit = async (emailAddress) => {
    setIsDataLoaded(true)
    const response = await submitUnsubcribeRequest(emailAddress)
    const updatedResponse = response?.data
    setMessage(updatedResponse)
    setIsDataLoaded(false)
    setEmailAddress(null)
  }

  return (
    <>
      <Header />
      <div className='page-content'>
        <Row className='home unsubscribe'>
          <Image src='/new-logo.jpeg' width={200} />
          <Card bordered={false} className='input-card' title='Unsubscribe'>
            <Row className='upload-per-colleague'>
              {!isDataLoaded && !message && (
                <Row gutter={16}>
                  <Typography gutterBottom>
                    Please confirm your email address
                  </Typography>
                  <Input
                    autoComplete='email'
                    autoFocus
                    fullWidth
                    margin='normal'
                    onChange={(event) =>
                      setEmailAddress(event.currentTarget.value)
                    }
                    placeholder='Email Address*'
                    required
                    value={emailAddress}
                  />
                  <Button
                    disabled={!emailAddress}
                    onClick={() => handleSubmit(emailAddress)}
                    style={{ marginTop: 30 }}
                    type='primary'
                  >
                    UNSUBSCRIBE
                  </Button>
                </Row>
              )}
              {/* {message && !isDataLoaded && (
                <Text style={{
                  textAlign: 'center',
                  margin: 'auto'
                }} type="success">{message}</Text>
              )} */}
              {message && !isDataLoaded && <Row className='upload-per-colleague'>
                <Text className='sub-text' strong>Thank you</Text>
                <Text strong style={{ textTransform: 'initial' }}>
                You have been successfully removed from our marketing campaign for the Infinity Long Range Golf Event in September 2024                </Text>
              </Row>}
            </Row>
          </Card>
        </Row>
      </div>
      <Footer />
    </>
  )
}

Unsubscribe.propTypes = {}

export default Unsubscribe
