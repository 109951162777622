import axios from 'axios'

import { campaignEndpoints } from './apiConfig'

export const submitUnsubcribeRequest = async (emailAddress) => {
  const { method, url } = campaignEndpoints.unsubscribe

  const config = {
    method,
    url: `${url}?email=${emailAddress}`,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const submitRSVPRequest = async (
  firstName, lastName, emailAddress, email, selectedSize
) => {
  const { method, url } = campaignEndpoints.rvsp

  const myHeaders = new Headers()
  myHeaders.append('tShirtSize', selectedSize)
  myHeaders.append('email', email)
  myHeaders.append('firstName', firstName)
  myHeaders.append('lastName', lastName)
  myHeaders.append('alternativeEmailAddress', emailAddress)

  const formdata = new FormData()
  formdata.append('tShirtSize', selectedSize)
  formdata.append('email', email)
  formdata.append('firstName', firstName)
  formdata.append('lastName', lastName)
  formdata.append('alternativeEmailAddress', emailAddress)

  const requestOptions = {
    method,
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  }

  let apiResponse
  try {
    const response = await fetch(url, requestOptions)
    apiResponse = await response.text()
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}
